<template>
    <div class="main">
        <div class="infos">
            <span v-if='waitingForBridgeConnection == true' class="waitingForBridgeConnection">Attente d'un enregistreur...</span>
    
            <p v-if="recording_duration != 0">{{ms2tc(recording_duration)}}</p>
        </div>

        <!-- VIEW PROMPTEUR / RECORDING -->
        <div v-if="view == 'recording'" id="prompter" class="center" :class="(settings.flip == true ? 'flip' : '')" :style="{
            width: settings.prompterwidth + '%',
            '--eyessquareheight': settings.eyessquareheight + 'px',
          }">
            <div class="eyesSquare_conatainer">
                <div id="eyes_square" class="center" :style="{ opacity: settings.eyessquareopacity / 100 }"></div>
            </div>
            <div id="toScroll" class="center">
                <p class="prompter_content" :style="{ 'font-size': settings.fontsize + 'px' }" ref="prompter_content">
                    {{ currentScriptText }}
                </p>
            </div>
        </div>
    
        <!-- BACKGROUND -->
        <div class="background_blackk"></div>
    </div>
</template>

<script>
const { io } = require("socket.io-client");
const socket = io(process.env.BRIDGE_URL);
const videoDuration = require('../../lib/time.js') // eslint-disable-line
import $ from "jquery"
export default {
    data() {
        return {
            view: "recording",
            settings: {
                eyessquareheight: 300,
                eyessquareopacity: 0,
                fontsize: 50,
                prompterwidth: 70,
                prompterSpeed: 2,
                flip : true
            },
            prompterOffset: null,
            prompter_move: "stop",
            scrollingStep: 5,
            waitingForBridgeConnection: false,
            recording: false,
            recording_duration: 0,
            currentScriptText: "",
        };
    },
    name: "Recbox Prompter",
    mounted() {
        var project_id = this.$route.query.project_id;

        //BONOUR
        socket.emit(
            "bonjour", {
                mode: "prompter",
                userAgent: navigator.userAgent || null,
                project_id: project_id,
            },
            (response) => {
                if (response.status >= 400) {
                    /*
                    this.$router.push({
                        path: "/",
                        query: {
                            ...this.$route.query,
                            error: response.message,
                        },
                    });
                    */
                }
            }
        );

        //ACTUALYSE STATE
        socket.on("state", (state) => {
            //console.debug("State : ", state);

            //RECORDER STATUE
            if (state.recorder != undefined && state.recorder != null) {
                //this.recorderConnected = true;
                this.waitingForBridgeConnection = false
            } else {
                //this.recorderConnected = false;
                this.waitingForBridgeConnection = true
            }

            //CURRENT SCRIPT TEXT
            if (state.currentScriptText != undefined) {
                this.currentScriptText = state.currentScriptText;
            }

            //PROMPTER SETTINGS : prompter_speed
            if (state.prompter_speed != undefined) {
                this.settings.prompterSpeed = state.prompter_speed;
            }

            //PROMPTER SETTINGS : prompter_size
            if (state.prompter_size != undefined) {
                this.settings.fontsize = state.prompter_size;
            }

            //PROMPTER SETTINGS : prompter_flip
            if (state.prompter_flip != undefined) {
                this.settings.flip = state.prompter_flip;
            }

            
            
        });

        //RECORDING STATE
        socket.on("recording_state", (recording_state) => {
            if (recording_state.recording == true && recording_state.recording != this.recording) {
                this.recording = true
                
                //START PROMPTER
                this.prompterOffset = $("body").height();
                this.$nextTick(() => {
                    setTimeout(() => {
                        $("#toScroll").css({ top: this.prompterOffset + "px" });
                        this.scrolling();
                    }, 500);
                });
                
            } else if (recording_state.recording == false) {
                this.recording = false
                this.prompterOffset = null
                setTimeout(()=>{
                    $("#toScroll").css({ top: "0px" });
                }, 1000)
            }

            if (recording_state.duration != undefined) {
                this.recording_duration = recording_state.duration
            }
        });
    },
    methods: {
        ms2tc: videoDuration.ms2tc,
        //PROMPTER SCROLLING
        scrolling() {
            if (this.recording == true) {
                var scrollDuration = 150 / this.settings.prompterSpeed;
                this.prompterOffset = this.prompterOffset - this.scrollingStep;

                $("#toScroll").animate({ top: this.prompterOffset + "px" },
                    scrollDuration,
                    "linear",
                    () => {
                        if (
                            this.prompterOffset > -$(".prompter_content").outerHeight() &&
                            this.recording == true
                        ) {
                            this.scrolling();
                        } else if (
                            this.prompterOffset <= -$(".prompter_content").outerHeight()
                        ) {
                            this.prompterOffset = $("body").height();
                            $("#toScroll").css({ top: this.prompterOffset + "px" });
                            this.scrolling();
                        }
                    }
                );
            }
        },
    },
};
</script>

<style src="./background.css" scoped>

</style>

<style src="./prompter.css" scoped>

</style>